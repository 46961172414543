import { useCallback, useEffect, useState } from 'react';
import { RotationDirection, SideWithAsset } from 'shared-types';

const useActions = (initialActions: SideWithAsset['actions']) => {
  const [actions, setActions] = useState<SideWithAsset['actions']>(initialActions);

  const handleRotate = useCallback((direction: RotationDirection) => {
    setActions((prev) => ({
      ...prev,
      rotation: prev.rotation + (direction === 'right' ? 90 : -90),
    }));
  }, []);

  const handleFlip = useCallback(() => {
    setActions((prev) => ({ ...prev, flip: !prev.flip }));
  }, []);

  const changePlace = useCallback((place: SideWithAsset['actions']['place']) => {
    setActions((prev) => ({ ...prev, place }));
  }, []);

  const changeType = useCallback((type: SideWithAsset['actions']['type']) => {
    setActions((prev) => ({ ...prev, type }));
  }, []);

  useEffect(() => {
    setActions(initialActions);
  }, [initialActions]);

  return {
    newActions: actions,
    setActions,
    handleRotate,
    handleFlip,
    changePlace,
    changeType,
  };
};

export default useActions;
