import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Asset, SideWithAsset } from 'shared-types';
import styled from 'styled-components';

import Box from 'components/Box/Box';
import FilePreview from 'components/ProductSide/FilePreview';

import useSessionStore from 'hooks/stores/useSessionStore';
import useActions from 'hooks/useActions';
import useDownloadPDF from 'hooks/useDownloadPDF';
import useEditor from 'hooks/useEditor';

import API from 'services/API';

import Editor from './Editor';
import Toolbox from './Toolbox';

const SFilename = styled.h5`
  color: #333;
  font-size: 14px;
  font-weight: 800;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 0px;
`;

type Props = {
  side: SideWithAsset;
};

const ProductSide = ({ side }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { actions, id: sideId, productId } = side;
  const asset = side?.asset as Asset;

  const handlePreview = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpen(true);
  }, []);

  const sessionId = useSessionStore((state) => state.sessionId);

  const { pdfFile: originalPdf, isDownloading } = useDownloadPDF({ fileUrl: asset.previewFile });

  const { mutate: updateSideMutation } = useMutation(
    `updateSide-${sideId}`,
    (variables: { sideId: SideWithAsset['id']; actions: SideWithAsset['actions'] }) =>
      API.updateSide(sessionId, variables.sideId, { actions: variables.actions }),
  );

  const { newActions, handleRotate, handleFlip, changePlace, changeType } = useActions(actions);

  const generatedPdf = useEditor({
    pdfFile: originalPdf,
    newActions,
    dimensions: asset.dimensions,
    cropArea: side.cropArea,
  });

  useEffect(() => {
    if (generatedPdf) {
      updateSideMutation({ sideId, actions: newActions });
    }
  }, [generatedPdf, newActions, sideId, updateSideMutation]);

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" width="100%">
        <FilePreview
          file={generatedPdf || originalPdf}
          loading={isDownloading}
          pageWidth={120}
          isFullPreview={false}
          onClick={handlePreview}
        />
      </Box>
      <Toolbox
        onRotate={(direction) => handleRotate(direction)}
        onFlip={handleFlip}
        productId={productId}
        sideId={sideId}
        onPreview={() => setIsOpen(true)}
      />
      <Box mt="4px">
        <SFilename>{asset.filename}</SFilename>
      </Box>
      {originalPdf && (
        <Editor
          isOpen={isOpen}
          pdfFile={generatedPdf}
          originalPdfFile={originalPdf}
          actions={newActions}
          width={asset.dimensions.width}
          height={asset.dimensions.width}
          onClose={() => setIsOpen(false)}
          onChangePlace={changePlace}
          onChangeType={changeType}
          onFlip={handleFlip}
          onRotate={handleRotate}
        />
      )}
    </>
  );
};

export default ProductSide;
