import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Button, Flex, Slider } from 'antd';

import API from 'services/API';

import Cropper, { Area, Point } from '../EasyCrop';

type CropModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  crop: { x: number; y: number };
  zoom: number;
  aspectRatio: number;
  sessionId: string;
  sideId: string;
  onCropChange: (location: Point) => void;
  onZoomChange: (zoom: number) => void;
  onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => void;
};

const CropModal = ({
  isOpen,
  onClose,
  onSave,
  sessionId,
  sideId,
  crop,
  aspectRatio,
  zoom,
  onCropChange,
  onZoomChange,
  onCropComplete,
}: CropModalProps) => {
  const { t } = useTranslation();

  const { data, isError, isLoading, isFetching } = useQuery('cropImage', () =>
    API.postGeneratePreviewForCrop(sessionId, sideId),
  );

  const isLoadingOrError = isLoading || isError || isFetching;
  return (
    <div
      style={{
        display: isOpen ? 'block' : 'none',
        position: 'fixed',
        zIndex: 1000,
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        overflow: 'auto', // Allows scrolling if the content is too long
        backgroundColor: 'gray',
      }}
      aria-label="Crop Modal"
    >
      {isLoadingOrError ? (
        <Flex justify="center" align="center" style={{ height: '100%' }}>
          {isLoading && <h2>{t('CropModal.Loading')}</h2>}
          {isError && <h2>{t('CropModal.Error')}</h2>}
        </Flex>
      ) : (
        <>
          <div
            style={{
              width: '100%',
              height: '90%',
              position: 'relative',
              top: '5px',
            }}
          >
            <Cropper
              image={data?.previewCropImageUrl}
              crop={crop}
              zoom={zoom}
              aspect={aspectRatio}
              onCropChange={onCropChange}
              onZoomChange={onZoomChange}
              onCropComplete={onCropComplete}
              objectFit="contain"
            />
          </div>
          <Flex justify="center" align="center" style={{ padding: '1rem' }}>
            <Slider
              value={zoom}
              onChange={onZoomChange}
              min={1}
              max={5}
              step={0.01}
              style={{ width: '50%' }}
            />
            <Button onClick={onSave} type="primary" danger style={{ marginLeft: '1rem' }}>
              {t('CropModal.Save')}
            </Button>
            <Button onClick={onClose} style={{ marginLeft: '1rem' }}>
              {t('Cancel')}
            </Button>
          </Flex>
        </>
      )}
    </div>
  );
};

export default CropModal;
