import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Button, Card, Flex } from 'antd';
import { equals } from 'ramda';
import { ActionsXorCropArea, Session, SideWithAsset } from 'shared-types';
import styled from 'styled-components';

import Box from 'components/Box/Box';
import { Area } from 'components/EasyCrop/types';
import FilePreview from 'components/ProductSide/FilePreview';
import Toolbox from 'components/ProductSide/Toolbox';

import useSessionStore from 'hooks/stores/useSessionStore';
import useActions from 'hooks/useActions';
import useCrop from 'hooks/useCrop';
import useDownloadPDF from 'hooks/useDownloadPDF';
import useEditor from 'hooks/useEditor';

import API from 'services/API';

import CropModal from './CropModal';

export const SCard = styled(Card)`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1rem;
  height: 100%;
`;

export const SDelete = styled(Box)`
  display: flex;
  justify-content: center;
`;

type Props = {
  side: SideWithAsset;
};

const PageCardEditor = ({ side }: Props) => {
  const { bleed, productSize } = useSessionStore((state) => ({
    bleed: state.session?.bleed,
    productSize: state.session?.productSize,
  }));

  const sessionId = useSessionStore((state) => state.sessionId);
  const queryClient = useQueryClient();
  const [isOpened, setIsOpened] = useState(false);
  const { crop, zoom, onCropChange, onZoomChange, onCropComplete, croppedArea, resetZoom } =
    useCrop();
  const [capturedArea, setCapturedArea] = useState<Area | null>(null);

  const { actions, id: sideId, productId, asset, cropArea } = side;
  const { pdfFile: originalPdf, isDownloading } = useDownloadPDF({ fileUrl: asset.previewFile });
  const { newActions, handleRotate, handleFlip } = useActions(actions);

  const { mutate } = useMutation(
    `updateSide-${sideId}`,
    (actionsOrCropArea: ActionsXorCropArea) => API.updateSide(sessionId, sideId, actionsOrCropArea),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('products');
      },
    },
  );

  const aspectRatio = Number(productSize?.width) / Number(productSize?.height);
  const imageAspectRatio = asset.dimensions ? asset.dimensions.width / asset.dimensions.height : 1;
  let assetHasTrimbox = false;
  if (asset.dimensions && asset.metadata?.trimbox) {
    if (
      Math.abs(asset.dimensions.width - asset.metadata.trimbox.width) > 0.01 ||
      Math.abs(asset.dimensions.height - asset.metadata.trimbox.height) > 0.01 ||
      asset.metadata.trimbox.x > 0 ||
      asset.metadata.trimbox.y > 0
    ) {
      assetHasTrimbox = true;
    }
  }
  const assetSize = {
    width: asset.dimensions.width,
    height: asset.dimensions.height,
  };

  const generatedPdf = useEditor({
    pdfFile: originalPdf,
    newActions,
    metaData: asset.metadata,
    dimensions: asset.dimensions,
    cropArea: cropArea || capturedArea, // use captured area if it exists
  });

  // when flag is set to true or rotation is bigger than 0 disable crop
  const disableCrop =
    newActions.flip || newActions.rotation !== 0 || actions.flip || actions.rotation !== 0;

  const handleOpenModal = () => {
    setIsOpened(true);
    resetZoom(); // Reset zoom when opening the modal
    setCapturedArea(null);
  };

  const handleCloseModal = () => {
    setIsOpened(false);
    setCapturedArea(null);
  };

  // on model close set captured area to cropped area
  const handleSaveAndCloseModal = () => {
    setIsOpened(false);
    setCapturedArea(croppedArea || null);
  };

  // update side actions when new actions change
  useEffect(
    function updateSideActions() {
      if (!equals(actions, newActions)) {
        mutate({ actions: newActions });
        setCapturedArea(null);
      }
    },
    [newActions],
  );

  // update crop area when captured area changes
  useEffect(
    function updateCropArea() {
      if (capturedArea && !equals(capturedArea, cropArea)) {
        mutate({ cropArea: capturedArea });
      }
    },
    [capturedArea],
  );

  // Reset capturedArea when sideId changes or switch to another side
  useEffect(() => {
    setCapturedArea(null);
  }, [sideId]);

  const createCropArea = (
    productSize: Session['productSize'],
    assetWidth: number,
    assetHeight: number,
  ) => {
    let cX, cY;
    const productWidth = Number(productSize.width);
    const productHeight = Number(productSize.height);
    console.log('w diff', assetWidth - productWidth);
    console.log('h diff', assetHeight - productHeight);

    const assetWidthHeightFactor = assetWidth / assetHeight;
    const productWidthHeightFactor = productWidth / productHeight;
    console.log('a diff', assetWidthHeightFactor);
    console.log('p diff', productWidthHeightFactor);

    //  if (assetWidth - productWidth > assetHeight - productHeight) {
    if (assetWidthHeightFactor > productWidthHeightFactor) {
      const scale = productHeight / assetHeight;
      const aW = assetWidth * scale;

      // we need to shift half of the difference between the scaled asset trimbox height and the product trimbox height
      const diff = (aW - productWidth) / 2;
      cX = (diff / aW) * 100;
      cY = 0;
      return { x: cX, y: cY, width: 100 - 2 * cX, height: 100 };
    }
    //  if (assetWidth - productWidth < assetHeight - productHeight) {
    else if (assetWidthHeightFactor < productWidthHeightFactor) {
      const scale = productWidth / assetWidth;
      const aH = assetHeight * scale;

      // we need to shift half of the difference between the scaled asset trimbox height and the product trimbox height
      const diff = (aH - productHeight) / 2;

      cX = 0;
      cY = (diff / aH) * 100;
      return { x: 0, y: cY, width: 100, height: 100 - 2 * cY };
    }

    //  if (assetWidth - productWidth == assetHeight - productHeight) {
    //if (assetWidthHeightFactor == productWidthHeightFactor) {
    else {
      return { x: 0, y: 0, width: 100, height: 100 };
    }
  };

  const calculateCropToIncludeBleed = (
    bleed: Session['bleed'],
    productSize: Session['productSize'],
    assetWidth: number,
    assetHeight: number,
  ): { x: number; y: number; width: number; height: number } => {
    // Calculate total product dimensions including bleed

    const cropArea = createCropArea(productSize, assetWidth, assetHeight);

    console.log('cropArea', cropArea);

    const productWidth = Number(productSize.width) + Number(bleed.left) + Number(bleed.right);
    const productHeight = Number(productSize.height) + Number(bleed.top) + Number(bleed.bottom);

    console.log('Asset dimensions:', { width: assetWidth, height: assetHeight });
    console.log('Product dimensions (including bleed):', {
      width: productWidth,
      height: productHeight,
    });

    const assetRatio = assetWidth / assetHeight;
    const productRatio = productWidth / productHeight;

    console.log('Aspect ratios:', { asset: assetRatio, product: productRatio });

    let cropX: number;
    let cropY: number;
    let cropWidth: number;
    let cropHeight: number;

    if (assetRatio > productRatio) {
      // Asset is wider relative to its height than the product
      const scaleY = (productHeight / Number(productSize.height)) * 100;
      // Asset is wider relative to its width than the product (or same ratio)
      cropWidth = (cropArea.width / scaleY) * 100;
      cropHeight = (100 / scaleY) * 100;
      cropX =
        (cropArea.x * scaleY) / 100 +
        (bleed.left / (productHeight + bleed.left + bleed.right)) * 100; //(bleed.left * scaleY) / 100; //cropArea.x
      cropY = (bleed.bottom / (productHeight + bleed.top + bleed.bottom)) * 100;
      console.log('1', Number(productSize.width), assetWidth, bleed, scaleY);
    } else {
      console.log(
        '2',
        Number(productSize.height),
        assetHeight,
        (Number(productSize.height) / assetHeight) * 100,
      );
      const scaleX = (productWidth / Number(productSize.width)) * 100;
      // Asset is taller relative to its width than the product (or same ratio)
      cropWidth = (100 / scaleX) * 100;
      cropHeight = (cropArea.height / scaleX) * 100;
      cropX =
        (cropArea.x * scaleX) / 100 +
        (bleed.left / (productWidth + bleed.left + bleed.right)) * 100;
      cropY = (cropArea.y * scaleX) / 100;
    }

    const result = {
      x: cropX,
      y: cropY,
      width: cropWidth,
      height: cropHeight,
    };

    console.log('Crop result:', result);

    return result;
  };

  const handleZoomOut = () => {
    if (!bleed || !productSize) return;
    const crop = calculateCropToIncludeBleed(
      bleed,
      productSize,
      asset.dimensions.width,
      asset.dimensions.height,
    );
    mutate({ cropArea: crop });
  };

  return (
    <Card key={side.id} style={{ padding: 0 }}>
      <Flex
        key={side.id}
        justify="flex-start"
        align="center"
        style={{ width: '100%', marginBottom: '0.5rem', height: '80vh' }}
        vertical
      >
        <Toolbox
          onRotate={(direction) => handleRotate(direction)}
          onFlip={handleFlip}
          onZoomOut={handleZoomOut}
          onCrop={handleOpenModal}
          productId={productId}
          sideId={sideId}
          showActions={['rotate', 'flip', 'crop', 'zoomOut']}
          disableActions={disableCrop ? ['crop'] : []}
        />
        <FilePreview
          file={generatedPdf || originalPdf}
          loading={isDownloading}
          isFullPreview={true}
          pageWidth={Number(productSize?.width) || 400}
        />
      </Flex>
      {isOpened && (
        <CropModal
          isOpen={isOpened}
          onClose={handleCloseModal}
          onSave={handleSaveAndCloseModal}
          sessionId={sessionId}
          sideId={sideId}
          crop={crop}
          zoom={zoom}
          aspectRatio={aspectRatio}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropComplete}
        />
      )}
    </Card>
  );
};

export default PageCardEditor;
